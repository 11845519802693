define('shareThis', function() {

  

  var ShareThis = function() {
    this.selector = '.widget__sharethis';
    this.publisherAttribute = 'data-sharethis-publisher';
    this.shortenAttribute = 'data-sharethis-shorten';
    this.scriptUrl = 'https://ws.sharethis.com/button/buttons.js';
    this.init();
  };

  ShareThis.prototype = {
    init: function() {
      this.setElements();
      if (this.hasElements()) {
        this.setPublisher();
        this.switchTo5x();
        this.load();
      }
    },
    setPublisher: function() {
      this.publisher = this.$elements[0].getAttribute(this.publisherAttribute);
    },
    setShortenedUrlValue: function() {
      this.shortenUrl = this.$elements[0].getAttribute(this.shortenAttribute) === 'true' ? true : false;
    },
    setElements: function() {
      this.$elements = document.querySelectorAll(this.selector);
    },
    hasElements: function() {
      return this.$elements.length > 0;
    },
    switchTo5x: function() {
      window.switchTo5x = true;
    },
    hasLoaded: function() {
      return typeof window.stlib !== 'undefined' && typeof window.stLight !== 'undefined';
    },
    startCheckIfLoaded: function() {
      this.listener = setInterval(this.checkLoaded.bind(this), 300);
    },
    endCheckIfLoaded: function() {
      clearInterval(this.listener);
    },
    checkLoaded: function() {
      if (this.hasLoaded()) {
        this.endCheckIfLoaded();
        this.render();
      }
    },
    getScript: function() {
      var js = document.createElement('script');
      js.src = this.scriptUrl;
      js.async = true;
      document.getElementsByTagName('head')[0].appendChild(js);
    },
    load: function() {
      if (this.hasLoaded()) {
        this.render(); return;
      }
      this.getScript();
      this.startCheckIfLoaded();
    },
    render: function() {
      this.setShortenedUrlValue();
      window.stLight.options({
        publisher: this.publisher,
        doNotHash: true,
        shorten: this.shortenUrl,
        doNotCopy: false,
        hashAddressBar: false
      });
    }
  };

  return new ShareThis();
});

